import React from 'react'
import purpleBanner from '../../resources/img/purple-banner@2x.jpg'
import facebook from '../../resources/img/facebook-icon.svg'
import instagram from '../../resources/img/instagram-icon.svg'
import {VIB_REGISTRATION_BASE_PATH} from '../../utils/paths'

export default function RestaurantItem({ restaurant }) {

  const renderCultivars = () => {
    const cultivars = restaurant.tags
    return cultivars.map(({name,image}, index) => {
      return <img src={VIB_REGISTRATION_BASE_PATH+image} key={index} alt={name} title={name} />
    })
  }

  return (
    <div className='_item _restaurant-item'>

      {
        restaurant.image && (
          <img className='_restaurant-image' src={VIB_REGISTRATION_BASE_PATH+restaurant.image} alt="" />
        )
      }
      <div className="_banner" style={{backgroundImage: `url(${purpleBanner})`}}>
        <h2>{restaurant.restaurant_name}</h2>
          {restaurant.booking_link && restaurant.booking_link !== 'NULL' && (
            <a href={restaurant.booking_link} className='btn _book-here'>BOOK HERE</a>
          )}
      </div>

      {
        restaurant.website && restaurant.website !== 'NULL' && (
          <p>Restaurant <span className='_text-gold'><i>Website</i></span><br />
          <a href={restaurant.website} target="_blank" rel="noreferrer">{restaurant.website}</a></p>
        )
      }

      <p>
        Restaurant <span className='_text-gold'><i>Location</i></span><br />
        {
          restaurant.google_maps_url && restaurant.google_maps_url !== '' && restaurant.google_maps_url !== 'NULL' ? (
            <>
              <a href={restaurant.google_maps_url} target="_blank" rel="noreferrer">
                {restaurant.unit_number && restaurant.unit_number} &nbsp;
                {restaurant.street_address && restaurant.street_address} &nbsp;
                {restaurant.suburb && restaurant.suburb} &nbsp;
                {restaurant.city && restaurant.city} &nbsp;
                {restaurant.postal_code && restaurant.postal_code}
              </a>
            </>
          ) : (
            <>                
              {restaurant.unit_number && restaurant.unit_number} &nbsp;
              {restaurant.street_address && restaurant.street_address} &nbsp;
              {restaurant.suburb && restaurant.suburb} &nbsp;
              {restaurant.city && restaurant.city} &nbsp;
              {restaurant.postal_code && restaurant.postal_code}
            </>
          )
        }
      </p>

      {
        restaurant.facebook && restaurant.facebook !== '' && restaurant.instagram && restaurant.instagram !== '' && (
          <div className='_social-media'>
            {restaurant.facebook && restaurant.facebook !== '' && (
              <a href={restaurant.facebook} target="_blank" rel="noreferrer">
                <img src={facebook} alt="" />
                facebook
              </a>
            )}
            {restaurant.instagram && restaurant.instagram !== '' && (
              <a href={restaurant.instagram} target="_blank" rel="noreferrer">
                <img src={instagram} alt="" />
                instagram
              </a>
            )}
          </div>
        )
      }

      <p style={{marginBottom: '15px'}}>
        Wines Available on <span className='_text-gold'><i>Menu</i></span><br />
      </p>
      <div className='_wines-avail'>
        {renderCultivars()}
      </div>
    </div>
  )
}
